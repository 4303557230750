<template>
  <v-container fluid class="mt-5">
    <v-row justify="center">
      <v-col cols="11">
        <v-expand-transition>
          <Loader v-if="loading" />
          <v-row v-else>
            <CreateUniform @created="getUniforms" />
            <UpdateUniform :uniformData="selectedUniform" @updated="getUniforms" />
            <DeleteUniform :uniformData="selectedUniform" @deleted="getUniforms" />

            <v-col
              cols="12"
              class="d-flex justify-end"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    height="56px"
                    class="ml-5"
                    v-bind="attrs"
                    v-on="on"
                    @click="createUniform()"
                  >
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </template>
                <span>Cadastrar Uniforme</span>
              </v-tooltip>
            </v-col>

            <v-col
              lg="2"
              md="2"
              sm="3"
              cols="6"
              v-for="(uniform, index) in uniforms"
              :key="uniform._id"
            >
              <v-card>
                <v-btn
                  fab
                  x-small
                  color="error"
                  class="delete-btn"
                  @click="deleteUniform(uniform)"
                >
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
                <div class="d-flex flex-column flex-no-wrap justify-center align-center pa-2" @click="updateUniform(uniform)">
                  <v-avatar
                    tile
                    left
                    size="80%"
                    height="100px"
                    color="#F7F7F7"
                    class="uniform-image"
                  >
                    <v-img
                      v-if="uniform.image"
                      :src="uniform.image"
                      contain
                      max-height="60%"
                      @error="imageErrorHandler(index)"
                    />

                    <span
                      v-else
                      class="display-2 white--text text-center"
                    >
                      <v-icon>
                        mdi-tshirt-crew
                      </v-icon>
                    </span>
                  </v-avatar>
                  <span class="mt-1">
                    {{truncate(uniform.name || '', 20)}}
                  </span>
                </div>
              </v-card>
            </v-col>

            <v-col
              v-if="uniforms.length === 0"
              cols="12"
            >
              <EmptyData />
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const imageOffIcon = require('@/assets/icons/image-off.png')

export default {
  components: {
    Loader: () => import('@/components/LoadingCards'),
    EmptyData: () => import('@/components/EmptyData'),
    CreateUniform: () => import('./components/CreateUniform.vue'),
    UpdateUniform: () => import('./components/UpdateUniform.vue'),
    DeleteUniform: () => import('./components/DeleteUniform.vue')
  },
  data () {
    return {
      loading: false,
      uniforms: [],
      selectedUniform: {}
    }
  },
  computed: {
    createUniformModal: {
      get () {
        return this.$store.state.createUniformModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'createUniformModal',
          value: val
        })
      }
    },
    updateUniformModal: {
      get () {
        return this.$store.state.updateUniformModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'updateUniformModal',
          value: val
        })
      }
    },
    deleteUniformModal: {
      get () {
        return this.$store.state.deleteUniformModal
      },
      set (val) {
        this.$store.commit({
          type: 'SET_MODAL_STATE',
          modal: 'deleteUniformModal',
          value: val
        })
      }
    }
  },
  mounted () {
    this.getUniforms()
  },
  methods: {
    getUniforms () {
      this.loading = true

      this.$http.get('/uniforms')
        .then((res) => {
          const { data } = res.data
          this.uniforms = data
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message)
        })
        .finally(() => (this.loading = false))
    },
    createUniform () {
      this.createUniformModal = true
    },
    updateUniform (uniform) {
      this.selectedUniform = uniform
      this.updateUniformModal = true
    },
    deleteUniform (uniform) {
      this.selectedUniform = uniform
      this.deleteUniformModal = true
    },
    imageErrorHandler (index) {
      this.uniforms[index].image = imageOffIcon
    }
  }
}
</script>

<style scoped>
.uniform-image {
  border: 1px solid #BDBDBD !important;
  border-radius: 3px !important;
}

.delete-btn {
  position: absolute;
  top: -10px;
  right: -10px;
  z-index: 5;
}

.delete-btn:hover {
  transform: scale(1.1);
}
</style>
